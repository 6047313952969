import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class UsersService {
    fetchUsers = (params) => new Promise((resolve, reject) => {
      const newParams = { ...params };
      newParams.includes = [...params.includes, 'media', 'roles'];

      const url = queryBuilderService.makeUrl('', 'users', newParams);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    fetchUser = (id) => new Promise((resolve, reject) => {
      axios.get(`users/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteUser = (id) => new Promise((resolve, reject) => {
      axios.delete(`users/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    createUser = (params) => new Promise((resolve, reject) => {
      axios.post('users', params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    updateUser = (id, params) => new Promise((resolve, reject) => {
      axios.patch(`users/${id}`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteMedia = (id, mediaId) => new Promise((resolve, reject) => {
      axios.delete(`users/${id}/media/${mediaId}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const usersService = new UsersService();

export default usersService;
