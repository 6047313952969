import TemplatesService from 'src/services/templatesService';
import { addEntities } from 'src/actions/baseActions';
import { normalize } from 'normalizr';
import { template as templateSchema } from 'src/schema';

import { DELETE_MEDIA } from './mediaActions';

export const SET_TEMPLATES_UI_LIST = '@templates/set-ui-list';
export const SET_TEMPLATES_UI_LIST_PARAMS = '@templates/set-ui-list-params';
export const SET_TEMPLATES_UI_TRASH = '@templates/set-ui-trash';

export function fetchTemplate(id, includes = []) {
  return async (dispatch) => {
    await TemplatesService.fetchTemplate(id, includes).then((response) => {
      const normalized = normalize(response, templateSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function fetchTemplates() {
  return async (dispatch, getState) => {
    const { templates: state } = getState();
    const { ui } = state;

    await TemplatesService.fetchTemplates(ui.list.params).then((response) => {
      const normalized = normalize(response.data, [templateSchema]);

      dispatch(addEntities(normalized.entities));

      const ids = normalized.result;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };

      dispatch({
        type: SET_TEMPLATES_UI_LIST,
        payload: {
          ids,
          pagination
        }
      });
    });
  };
}

export function fetchTemplatesDeleted() {
  return async (dispatch, getState) => {
    const { templates: state } = getState();
    const { ui } = state;

    await TemplatesService.fetchTemplates(ui.trash.params).then((response) => {
      const normalized = normalize(response.data, [templateSchema]);
      dispatch(addEntities(normalized.entities));

      const ids = normalized.result;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };

      dispatch({
        type: SET_TEMPLATES_UI_TRASH,
        payload: {
          ids,
          pagination
        }
      });
    });
  };
}

export function updateUiTrashParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATES_UI_TRASH,
      payload: {
        params
      }
    });
  };
}

export function updateUiListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATES_UI_LIST_PARAMS,
      payload: {
        params
      }
    });
  };
}

export function updateUiTrash(data) {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATES_UI_TRASH,
      payload: { ...data }
    });
  };
}

export function updateTemplate(id, params) {
  return async (dispatch) => {
    await TemplatesService.updateTemplate(id, params).then((response) => {
      const normalized = normalize(response, templateSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function createTemplate(params) {
  return async (dispatch) => {
    const response = await TemplatesService.createTemplate(params);
    const normalized = normalize(response, templateSchema);
    dispatch(addEntities(normalized.entities));
    return response.id;
  };
}

export function deleteTemplate(id, force = false) {
  return async () => {
    await TemplatesService.deleteTemplate(id, force);
  };
}

export function restoreTemplate(id) {
  return async () => {
    await TemplatesService.restoreTemplate(id);
  };
}

export function deleteMedia(id, mediaId) {
  return async (dispatch) => {
    await TemplatesService.deleteMedia(id, mediaId);
    dispatch({
      type: DELETE_MEDIA,
      payload: { id: mediaId }
    });
  };
}
