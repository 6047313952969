import CriterionTemplatesService from 'src/services/criterionTemplatesService';

export const SET_CRITERION_TEMPLATE = '@criterion-templates/set-criterion-template';
export const SET_CRITERION_TEMPLATES_LIST = '@criterion-templates/set-criterion-templates-list';
export const SET_CRITERION_TEMPLATES_LIST_PARAMS = '@criterion-templates/set-criterion-templates-list-params';

export function fetchCriterionTemplate(id) {
  return async (dispatch) => {
    await CriterionTemplatesService.fetchCriterionTemplate(id).then((response) => {
      dispatch({
        type: SET_CRITERION_TEMPLATE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function fetchCriterionTemplates() {
  return async (dispatch, getState) => {
    const { criterionTemplates: state } = getState();
    const { listParams: params } = state;

    await CriterionTemplatesService.fetchCriterionTemplates(params).then((response) => {
      const items = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_CRITERION_TEMPLATES_LIST,
        payload: {
          items,
          pagination
        }
      });
    });
  };
}

export function updateListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_CRITERION_TEMPLATES_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}

export function deleteCriterionTemplate(id) {
  // The function is here even if it doesn't impact the
  // redux state because I prefer to keep homogeneity and to be ready fo future changes
  return async () => {
    await CriterionTemplatesService.deleteCriterionTemplate(id);
  };
}

export function updateCriterionTemplate(id, params) {
  return async (dispatch) => {
    await CriterionTemplatesService.updateCriterionTemplate(id, params).then((response) => {
      dispatch({
        type: SET_CRITERION_TEMPLATE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function createCriterionTemplate(params) {
  return async (dispatch) => {
    await CriterionTemplatesService.createCriterionTemplate(params).then((response) => {
      dispatch({
        type: SET_CRITERION_TEMPLATE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function setCriterionTemplate(item) {
  return (dispatch) => {
    dispatch({
      type: SET_CRITERION_TEMPLATE,
      payload: {
        item,
      }
    });
  };
}
