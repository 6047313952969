import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class CriterionTemplatesService {
  fetchCriterionTemplates = (params) => new Promise((resolve, reject) => {
    const url = queryBuilderService.makeUrl('', 'criterion-templates', params);

    axios.get(url).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  fetchCriterionTemplate = (id) => new Promise((resolve, reject) => {
    axios.get(`criterion-templates/${id}?include=scope`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  createCriterionTemplate = (params) => new Promise((resolve, reject) => {
    axios.post('criterion-templates', params).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  updateCriterionTemplate = (id, params) => new Promise((resolve, reject) => {
    axios.patch(`criterion-templates/${id}`, params).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  deleteCriterionTemplate = (id) => new Promise((resolve, reject) => {
    axios.delete(`criterion-templates/${id}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
}

const criterionTemplatesService = new CriterionTemplatesService();

export default criterionTemplatesService;
