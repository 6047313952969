import { addEntities } from 'src/actions/baseActions';
import NodeDefinitionService from 'src/services/nodeDefinitionsService';
import { normalize } from 'normalizr';
import { nodeDefinition as nodeDefinitionSchema } from 'src/schema';
import { DELETE_MEDIA } from './mediaActions';

export function fetchNodeDefinition(id) {
  return async (dispatch) => {
    const response = await NodeDefinitionService.fetchNodeDefinition(id);
    const normalized = normalize(response, nodeDefinitionSchema);
    dispatch(addEntities(normalized.entities));
  };
}

export function fetchNodeDefinitions(params) {
  return async (dispatch) => {
    const response = await NodeDefinitionService.fetchNodeDefinitions(params);
    const normalized = normalize(response.data, [nodeDefinitionSchema]);
    dispatch(addEntities(normalized.entities));
  };
}

export function updateNodeDefinition(id, params) {
  return async (dispatch) => {
    await NodeDefinitionService.updateNodeDefinition(id, params).then((response) => {
      const normalized = normalize(response, nodeDefinitionSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function deleteMedia(id, mediaId) {
  return async (dispatch) => {
    await NodeDefinitionService.deleteMedia(id, mediaId);
    dispatch({
      type: DELETE_MEDIA,
      payload: { id: mediaId }
    });
  };
}
