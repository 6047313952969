/* eslint-disable no-param-reassign */
import produce from 'immer';
import _ from 'lodash';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
} from 'src/actions/accountActions';
import { DELETE_MEDIA } from 'src/actions/mediaActions';
import {
  SET_USER,
} from 'src/actions/usersActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case SET_USER: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        if (state.user && state.user.id === user.id) {
          draft.user = user;
        }
      });
    }

    case DELETE_MEDIA: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        if (state.user) {
          const media = [...state.user.media];
          _.remove(media, (item) => item.id === id);
          draft.user = { ...state.user };
          draft.user.media = media;
        } else {
          draft.user = { ...state.user };
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
