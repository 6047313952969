import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class ProductsService {
  searchProducts = (query) => new Promise((resolve, reject) => {
    axios.get('products/search', {
      params: {
        query
      }
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  fetchProducts = (params) => new Promise((resolve, reject) => {
    const url = queryBuilderService.makeUrl('', 'products', params);

    axios.get(url).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  getPropValue = (product, slug, locale = null) => {
    const prop = product.props.find((element) => element.slug === slug);

    if (!prop || prop === undefined) {
      return null;
    }

    const { value } = prop;

    if (!value || value === undefined) {
      return null;
    }

    const { body } = value;

    if (!body || body === undefined) {
      return null;
    }

    if (locale !== null && body[locale] !== undefined) {
      return body[locale];
    }

    return body;
  }
}

const productsService = new ProductsService();

export default productsService;
