import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class ScopesService {
    fetchScopes = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'scopes', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })
}

const scopesService = new ScopesService();

export default scopesService;
