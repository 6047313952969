export const ENQUEUE_SNACKBAR = '@snackbar/enqueue';
export const CLOSE_SNACKBAR = '@snackbar/close';
export const REMOVE_SNACKBAR = '@snackbar/remove';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return (dispatch) => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      payload: {
        notification: {
          ...notification,
          key: key || new Date().getTime() + Math.random(),
        },
      }
    });
  };
};

export const closeSnackbar = (key) => (dispatch) => {
  dispatch({
    type: CLOSE_SNACKBAR,
    payload: {
      dismissAll: !key,
      key
    }
  });
};

export const removeSnackbar = (key) => (dispatch) => {
  dispatch({
    type: REMOVE_SNACKBAR,
    payload: {
      key
    }
  });
};
