import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AdminGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  // If the user is logged but it is not an admin => logout
  const { roles } = account.user;
  const adminRole = roles.find((item) => item.slug === 'super-admin' || item.slug === 'super-user');

  if (!adminRole) {
    return <Redirect to="/403" />;
  }

  return children;
}

AdminGuard.propTypes = {
  children: PropTypes.any
};

export default AdminGuard;
