import ShootingTemplatesService from 'src/services/shootingTemplatesService';
import ShootingTemplateNodesService from 'src/services/shootingTemplateNodesService';
import NodeDefinitionsService from 'src/services/nodeDefinitionsService';
import TemplatesService from 'src/services/templatesService';
import { addEntities } from 'src/actions/baseActions';
import { normalize } from 'normalizr';
import {
  shootingTemplate as shootingTemplateSchema,
  template as templateSchema,
  shootingTemplateNode as shootingTemplateNodeSchema,
  nodeDefinition as nodeDefinitionSchema
} from 'src/schema';
import _ from 'lodash';
import { fetchNodeDefinition } from '../nodeDefinitionsActions';
import { updateNode } from '../shootingTemplates/nodesActions';

export const UPDATE_TEMPLATES_UI_GHOSTS_MAPPING = '@templates/update-ui-ghosts-mapping';
export const UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL = '@templates/update-ui-ghosts-mapping-modal';

export function updateUiGhostsMapping(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING,
      payload: { ...data }
    });
  };
}

export function updateUiGhostsMappingModal(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL,
      payload: { ...data }
    });
  };
}

export function fetchData(templateId) {
  return async (dispatch) => {
    const templateResponse = await TemplatesService.fetchTemplate(templateId, ['node_definitions']);
    const templateNormalized = normalize(templateResponse, templateSchema);
    dispatch(addEntities(templateNormalized.entities));

    const response = await ShootingTemplatesService.fetchShootingTemplates({
      filters: [
        ['scoring_template_id', templateId]
      ],
      includes: [
        'nodes'
      ]
    });
    const { data } = response;
    const normalized = normalize(data, [shootingTemplateSchema]);
    dispatch(addEntities(normalized.entities));

    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING,
      payload: {
        id: templateId,
        shootingTemplateId: data.length > 0 ? data[0].id : null
      }
    });
  };
}

export function fetchModalGhostNodes() {
  return async (dispatch, getState) => {
    const { templates: state } = getState();
    const {
      params,
      shootingTemplateNodeIds: currentIds,
      nodeDefinitionId
    } = state.ui.ghostsMapping.modal;

    const response = await ShootingTemplateNodesService.fetchNodes(params);
    const normalized = normalize(response.data, [shootingTemplateNodeSchema]);

    const { shootingTemplateNodes, media } = normalized.entities;

    dispatch(addEntities({
      shootingTemplateNodes,
      media
    }));

    const ids = normalized.result;
    const pagination = {
      currentPage: response.meta.current_page,
      from: response.meta.from,
      to: response.meta.to,
      lastPage: response.meta.last_page,
      perPage: response.meta.per_page,
      total: response.meta.total,
    };
    const newIds = response.meta.current_page === 1 ? ids : currentIds.concat(ids);

    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL,
      payload: {
        shootingTemplateNodeIds: newIds,
        pagination
      }
    });

    const {
      shootingTemplateNodes: shootingTemplateNodesState,
    } = getState();

    const newShootingTemplateNodes = _.filter(
      shootingTemplateNodesState.entities,
      (item) => _.includes(newIds, item.id)
    );

    const selectedShootingTemplateNodeIds = [];
    _.each(newShootingTemplateNodes, (item) => {
      if (_.includes(item.node_definitions, nodeDefinitionId)) {
        selectedShootingTemplateNodeIds.push(item.id);
      }
    });

    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL,
      payload: {
        selectedShootingTemplateNodeIds,
      }
    });
  };
}

export function toggleModalGhostToNodeDefinition(ghostId) {
  return (dispatch, getState) => {
    const { templates: state } = getState();
    const {
      selectedShootingTemplateNodeIds: currentIds,
      shootingTemplateNodeIdsTouched: currentTouched
    } = state.ui.ghostsMapping.modal;

    let newIds = [...currentIds];
    if (_.includes(currentIds, ghostId)) {
      newIds = _.without(currentIds, ghostId);
    } else {
      newIds.push(ghostId);
    }

    const newTouched = [...currentTouched];
    if (!_.includes(currentTouched, ghostId)) {
      newTouched.push(ghostId);
    }

    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL,
      payload: {
        selectedShootingTemplateNodeIds: newIds,
        shootingTemplateNodeIdsTouched: newTouched
      }
    });
  };
}

export function syncModalGhostNodes() {
  return async (dispatch, getState) => {
    const {
      templates: templatesState,
      shootingTemplateNodes: shootingTemplateNodesState,
    } = getState();

    const {
      nodeDefinitionId,
      selectedShootingTemplateNodeIds: selectedIds,
      shootingTemplateNodeIdsTouched: touchedIds
    } = templatesState.ui.ghostsMapping.modal;

    const {
      entities: shootingTemplateNodes
    } = shootingTemplateNodesState;

    const promises = [];
    _.each(touchedIds, (touchedId) => {
      const touched = shootingTemplateNodes[touchedId];
      const { node_definitions: currentNodeDefinitionIds } = touched;
      const ids = [...currentNodeDefinitionIds];

      if (_.includes(selectedIds, touched.id) && !_.includes(ids, nodeDefinitionId)) {
        // Node defintion ID to add
        ids.push(nodeDefinitionId);
        promises.push(ShootingTemplateNodesService.updateNode(touched.id, {
          node_definition_ids: ids
        }));
      } else if (!_.includes(selectedIds, touched.id) && _.includes(ids, nodeDefinitionId)) {
        // Node defintion ID to remove
        promises.push(ShootingTemplateNodesService.updateNode(touched.id, {
          node_definition_ids: _.without(ids, nodeDefinitionId)
        }));
      }

      // All other cases are not to consider (they are not really touched)
    });

    const results = await Promise.all(promises);
    const normalized = normalize(results, [shootingTemplateNodeSchema]);

    dispatch(addEntities(normalized.entities));
    dispatch(fetchNodeDefinition(nodeDefinitionId));

    dispatch({
      type: UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL,
      payload: {
        shootingTemplateNodeIdsTouched: []
      }
    });
  };
}

export function reorderGhostNodes(nodeDefinition, ghosts) {
  return async (dispatch) => {
    const result = await NodeDefinitionsService.updateNodeDefinition(nodeDefinition.id, {
      shooting_template_node_ids: _.map(ghosts, (item) => item.id)
    });
    const normalized = normalize(result, nodeDefinitionSchema);
    dispatch(addEntities(normalized.entities));
  };
}

export function detachGhostNode(ghost, nodeDefinition) {
  return async (dispatch) => {
    const updatedNodeDefinitionsId = _.without(ghost.node_definition_ids, nodeDefinition.id);

    // Update nodeDefinition entity
    const nodeDefinitionUpdated = { ...nodeDefinition };
    nodeDefinitionUpdated.shooting_template_node_ids = _.without(
      nodeDefinition.shooting_template_node_ids,
      ghost.id
    );

    // Update ghost entity
    await dispatch(updateNode(ghost.id, {
      node_definition_ids: updatedNodeDefinitionsId
    }));

    // dispatch(fetchNodeDefinition(nodeDefinition.id));
    await dispatch(addEntities({
      nodeDefinitions: {
        [nodeDefinition.id]: nodeDefinitionUpdated
      }
    }));
  };
}
