import ModelChangeItemsService from 'src/services/modelChangeItemsService';
import {
  modelChangeItem as modelChangeItemSchema,
} from 'src/schema';
import { normalize } from 'normalizr';
import { addEntities } from '../baseActions';

export const REMOVE_MODEL_CHANGE_ITEM = '@model-change-items/remove-model-change-item';

export function fetchModelChangeItems(templateId) {
  return async (dispatch) => {
    const response = await ModelChangeItemsService.fetchModelChangeItems({
      limit: 1000,
      filters: [
        ['template_id', templateId]
      ]
    });
    const normalizedData = normalize(response.data, [modelChangeItemSchema]);
    dispatch(addEntities(normalizedData.entities));
  };
}

export function removeModelChangeItem(id) {
  return async (dispatch) => {
    await ModelChangeItemsService.deleteModelChangeItem(id);
    dispatch({
      type: REMOVE_MODEL_CHANGE_ITEM,
      payload: {
        id
      }
    });
  };
}

export function createModelChangeItem(attributes) {
  return async (dispatch) => {
    const response = await ModelChangeItemsService.createModelChangeItem(attributes);
    const normalizedData = normalize(response, modelChangeItemSchema);
    dispatch(addEntities(normalizedData.entities));
  };
}
