/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_SHOOTING_TEMPLATES_UI_LIST,
  SET_SHOOTING_TEMPLATES_UI_LIST_PARAMS,
  SET_SHOOTING_TEMPLATES_UI_TRASH,
  SET_SHOOTING_TEMPLATES_UI_EDIT
} from 'src/actions/shootingTemplatesActions';
import { ADD_ENTITIES } from 'src/actions/baseActions';

const initialState = {
  entities: {},
  ui: {
    list: {
      ids: [],
      params: {
        includes: [],
        sortBy: [],
        limit: 1000,
        filters: [],
        page: 1
      },
      pagination: {
        currentPage: 1,
        from: 1,
        to: 10,
        lastPage: 1,
        perPage: 10,
        total: 0,
      }
    },
    trash: {
      ids: [],
      params: {
        includes: [],
        sortBy: [],
        limit: 1000,
        filters: [
          ['trashed', 'only']
        ],
        page: 1
      },
      pagination: {
        currentPage: 1,
        from: 1,
        to: 10,
        lastPage: 1,
        perPage: 10,
        total: 0,
      }
    },
    edit: {
      params: {
        expandAction: null,
        lastExpandAction: null
      },
    }
  }
};

const shootingTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { shootingTemplates } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...shootingTemplates };
      });
    }

    case SET_SHOOTING_TEMPLATES_UI_LIST: {
      const { ids, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.ui.list.ids = ids;
        draft.ui.list.pagination = pagination;
      });
    }

    case SET_SHOOTING_TEMPLATES_UI_LIST_PARAMS: {
      const { params } = action.payload;

      return produce(state, (draft) => {
        draft.ui.list.params = { ...state.ui.list.params, ...params };
      });
    }

    case SET_SHOOTING_TEMPLATES_UI_TRASH: {
      const { ids, pagination, params } = action.payload;

      return produce(state, (draft) => {
        if (ids !== undefined) {
          draft.ui.trash.ids = ids;
        }

        if (pagination !== undefined) {
          draft.ui.trash.pagination = pagination;
        }

        if (params !== undefined) {
          draft.ui.trash.params = { ...state.ui.trash.params, ...params };
          draft.ui.trash.params.filters.push(['trashed', 'only']);
        }
      });
    }

    case SET_SHOOTING_TEMPLATES_UI_EDIT: {
      const { params } = action.payload;

      return produce(state, (draft) => {
        draft.ui.edit.params = { ...state.ui.edit.params, ...params };
      });
    }

    default: {
      return state;
    }
  }
};

export default shootingTemplatesReducer;
