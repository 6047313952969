import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';

function Auth({ children }) {
  const queryStringParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  const initAuth = useCallback(async () => {
    authService.setAxiosInterceptors({
      onLogout: () => dispatch(logout())
    });

    try {
      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        const user = await authService.loggedUser();
        await dispatch(setUserData(user));
      }
    } catch (e) {
      dispatch(logout());
    }
  }, [dispatch]);

  useEffect(() => {
    /*  VAV3-1018 "slient login" feature removed
    // Check if the query string has a token in order to make a "silent login"
    const token = queryStringParams.get('token');
    if (token !== undefined && token !== null && !authService.isAuthenticated()) {
      authService.setSession(token);
    }
    */

    initAuth().then(() => {
      setLoading(false);
    });
  }, [initAuth, queryStringParams]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
