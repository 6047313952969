import { Query } from 'cogent-js/src';

class QueryBuilderService {
    makeUrl = (baseUrl, model, params) => {
      const builder = new Query({
        base_url: baseUrl
      });

      const {
        sortBy, limit, filters, page, includes
      } = params;

      // Base query
      let query = builder
        .for(model);

      // Includes relationships
      if (includes !== undefined && includes.length > 0) {
        query.includes(...includes);
      }

      // Sorting
      if (sortBy !== undefined && sortBy.length > 0) {
        const sortByElements = [];
        sortBy.forEach((element) => {
          const [value, dir] = element;
          // dir could be 'asc' or 'desc'
          const sort = dir === 'desc' ? `-${value}` : value;
          sortByElements.push(sort);
        });
        query = query.sort(...sortByElements);
      }

      // Filtering
      if (filters !== undefined && filters.length > 0) {
        filters.forEach((element) => {
          const [key, value] = element;

          if (Array.isArray(value)) {
            query = query.whereIn(key, value);
          } else {
            query = query.where(key, value);
          }
        });
      }

      // Pagination page limit
      if (limit !== undefined && Number.isInteger(limit)) {
        query = query.limit(limit);
      }

      // Pagination page selection
      if (page !== undefined && Number.isInteger(page)) {
        query = query.page(page);
      }

      return query.url();
    }
}

const queryBuilderService = new QueryBuilderService();

export default queryBuilderService;
