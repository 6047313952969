import axios from 'src/utils/axios';

class MediaService {
    getMediaByCollection = (object, collection, onlyFirst = false) => {
      let media = [];
      if (object.media !== undefined && object.media.length > 0) {
        media = object.media.filter((value) => value.collection === collection);
      }

      if (media.length > 0) {
        return onlyFirst ? media[0] : media;
      }

      return null;
    }

    getMediaBlobUrl = async (object, thumb = false) => {
      if (!object) {
        return null;
      }

      const url = thumb ? object.file_thumb : object.url;

      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(response.data);
    }
}

const mediaService = new MediaService();

export default mediaService;
