/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ADD_ENTITIES } from 'src/actions/baseActions';

const initialState = {
  entities: {},
};

const statusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { statuses } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...statuses };
      });
    }

    default: {
      return state;
    }
  }
};

export default statusesReducer;
