import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class NodeDefinitionsService {
    fetchNodeDefinition = (id) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', `node-definitions/${id}`, {});

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    fetchNodeDefinitions = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'node-definitions', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    updateNodeDefinition = (id, params) => new Promise((resolve, reject) => {
      axios.patch(`node-definitions/${id}`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    deleteMedia = (id, mediaId) => new Promise((resolve, reject) => {
      axios.delete(`node-definitions/${id}/media/${mediaId}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const nodeDefinitionsService = new NodeDefinitionsService();

export default nodeDefinitionsService;
