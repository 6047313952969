/* eslint-disable no-underscore-dangle */
import { addEntities } from 'src/actions/baseActions';
import { normalize } from 'normalizr';
import { shootingTemplateNode as shootingTemplateNodeSchema, media as mediaSchema } from 'src/schema';
import shootingTemplateNodesService from 'src/services/shootingTemplateNodesService';
import _ from 'lodash';
import { DELETE_MEDIA } from '../mediaActions';

export function updateNode(id, params) {
  return async (dispatch) => {
    await shootingTemplateNodesService.updateNode(id, params).then((response) => {
      const normalized = normalize(response, shootingTemplateNodeSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function createMedia(id, data) {
  return async (dispatch) => {
    shootingTemplateNodesService.createMedia(id, data).then((response) => {
      const normalized = normalize(response, mediaSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function deleteMedia(id, mediaId) {
  return async (dispatch) => {
    await shootingTemplateNodesService.deleteMedia(id, mediaId);
    dispatch({
      type: DELETE_MEDIA,
      payload: { id: mediaId }
    });
  };
}

/**
 * Reorder a set of nodes in the same level
 *
 * @param {array} nodes
 * @returns
 */
export function reorderNodes(nodes) {
  // It is supposed that the nodes array is already sorted correctly.
  // It is needed to fix the _left and _right attributes
  let position = 0;

  _.each(nodes, (node) => {
    position = node._left < position || position === 0 ? node._left : position;
  });

  const updatedNodes = _.map(nodes, (node) => {
    const updatedNode = { ...node };

    const distance = updatedNode._right - updatedNode._left;
    updatedNode._left = position;
    updatedNode._right = position + distance;

    position = updatedNode._right + 1; // Next position

    return updatedNode;
  });

  return async (dispatch) => {
    const promises = [];
    _.each(updatedNodes, (node) => {
      promises.push(shootingTemplateNodesService.updateNode(node.id, {
        _left: node._left,
        _right: node._right
      }));
    });

    const results = await Promise.all(promises);
    const normalized = normalize(results, [shootingTemplateNodeSchema]);
    dispatch(addEntities(normalized.entities));
  };
}
