import RolesService from 'src/services/rolesService';

export const SET_ROLES_LIST = '@roles/set-roles-list';

export function fetchRoles() {
  return async (dispatch, getState) => {
    const { roles: state } = getState();
    const { listParams: params } = state;

    await RolesService.fetchRoles(params).then((response) => {
      const list = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_ROLES_LIST,
        payload: {
          list,
          pagination
        }
      });
    });
  };
}
