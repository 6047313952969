import { schema } from 'normalizr';

export const status = new schema.Entity('statuses');

export const media = new schema.Entity('media');

export const nodeDefinition = new schema.Entity('nodeDefinitions', {
  media: [media],
});

export const template = new schema.Entity('templates', {
  media: [media],
  node_definitions: [nodeDefinition]
});

export const shootingTemplateNode = new schema.Entity('shootingTemplateNodes', {
  media: [media],
  node_definitions: [nodeDefinition]
});

export const shootingTemplate = new schema.Entity('shootingTemplates', {
  nodes: [shootingTemplateNode],
});

export const modelChangeItem = new schema.Entity('modelChangeItems');
