import UsersService from 'src/services/usersService';
import ProductsService from 'src/services/productsService';
import _ from 'lodash';

import { DELETE_MEDIA } from './mediaActions';

export const SET_USER = '@users/set-user';
export const SET_USERS_LIST = '@users/set-users-list';
export const SET_USERS_LIST_PARAMS = '@users/set-users-list-params';
export const SET_PRODUCTS_EXCLUDED_LIST = '@users/set-products-excluded-list-params';
export const SET_PRODUCTS_EXCLUDED_LIST_PARAMS = '@users/set-products-excluded-list-params';

export function fetchUser(id) {
  return async (dispatch) => {
    await UsersService.fetchUser(id).then((response) => {
      dispatch({
        type: SET_USER,
        payload: {
          user: response,
        }
      });
    });
  };
}

export function fetchProductsExcluded() {
  return async (dispatch, getState) => {
    const { users: state } = getState();
    const { productsExcluded } = state;
    const { user } = state;
    const { listParams: params } = productsExcluded;

    const idsFilter = user.products_excluded && user.products_excluded.length > 0
      ? user.products_excluded
      : 'null'; // Null is useful to avoid the retrieval of any data

    params.filters.push(['_id', idsFilter]);

    await ProductsService.fetchProducts(params).then((response) => {
      const items = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_PRODUCTS_EXCLUDED_LIST,
        payload: {
          items,
          pagination
        }
      });
    });
  };
}

export function updateProductsExcludedListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTS_EXCLUDED_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}

export function fetchUsers() {
  return async (dispatch, getState) => {
    const { users: state } = getState();
    const { listParams: params } = state;

    await UsersService.fetchUsers(params).then((response) => {
      const users = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_USERS_LIST,
        payload: {
          users,
          pagination
        }
      });
    });
  };
}

export function updateListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_USERS_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}

export function deleteUser(id) {
  // The function is here even if it doesn't impact the
  // redux state because I prefer to keep homogeneity and to be ready fo future changes
  return async () => {
    await UsersService.deleteUser(id);
  };
}

export function updateUser(id, params) {
  return async (dispatch) => {
    await UsersService.updateUser(id, params).then((response) => {
      dispatch({
        type: SET_USER,
        payload: {
          user: response,
        }
      });
    });
  };
}

export function createUser(params) {
  return async (dispatch) => {
    await UsersService.createUser(params).then((response) => {
      dispatch({
        type: SET_USER,
        payload: {
          user: response,
        }
      });
    });
  };
}

export function addProductExcluded(productId) {
  return async (dispatch, getState) => {
    const { users: state } = getState();
    const { user } = state;

    const params = {
      products_excluded: _.concat(user.products_excluded, productId)
    };

    await UsersService.updateUser(user.id, params).then((response) => {
      dispatch({
        type: SET_USER,
        payload: {
          user: response,
        }
      });
    });
  };
}

export function removeProductExcluded(productId) {
  return async (dispatch, getState) => {
    const { users: state } = getState();
    const { user } = state;

    const products = [...user.products_excluded];
    _.pull(products, productId);
    const params = {
      products_excluded: products
    };

    await UsersService.updateUser(user.id, params).then((response) => {
      dispatch({
        type: SET_USER,
        payload: {
          user: response,
        }
      });
    });
  };
}

export function deleteMedia(id, mediaId) {
  return async (dispatch) => {
    await UsersService.deleteMedia(id, mediaId);
    dispatch({
      type: DELETE_MEDIA,
      payload: { id: mediaId }
    });
  };
}
