/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from 'src/components/AdminGuard';

let appRoutes = [
  {
    exact: true,
    path: '/app',
    component: () => <Redirect to="/app/dashboard" />
  },
  {
    exact: true,
    path: '/app/account',
    component: lazy(() => import('src/views/pages/AccountView'))
  },
  {
    exact: true,
    path: '/app/dashboard',
    component: lazy(() => import('src/views/dashboard/DashboardView'))
  },
  {
    exact: true,
    path: '/app/users',
    component: lazy(() => import('src/views/users/UserListView'))
  },
  {
    exact: true,
    path: '/app/users/create',
    component: lazy(() => import('src/views/users/UserCreateView'))
  },
  {
    exact: true,
    path: '/app/users/:id/edit',
    component: lazy(() => import('src/views/users/UserEditView'))
  },
  {
    exact: true,
    path: '/app/templates',
    component: lazy(() => import('src/views/templates/ListView'))
  },
  {
    exact: true,
    path: '/app/templates/create',
    component: lazy(() => import('src/views/templates/CreateView'))
  },
  {
    exact: true,
    path: '/app/templates/:id/edit',
    component: lazy(() => import('src/views/templates/EditView'))
  },
  {
    exact: true,
    path: '/app/templates/:id/edit/instructions',
    component: lazy(() => import('src/views/templates/ManageInstructionsView'))
  },
  {
    exact: true,
    path: '/app/criterion-templates',
    component: lazy(() => import('src/views/criterionTemplates/ListView'))
  },
  {
    exact: true,
    path: '/app/criterion-templates/create',
    component: lazy(() => import('src/views/criterionTemplates/CreateView'))
  },
  {
    exact: true,
    path: '/app/criterion-templates/:id/edit',
    component: lazy(() => import('src/views/criterionTemplates/EditView'))
  }
];

const isGhostsEnabled = process.env.REACT_APP_GHOSTS_ENABLED
  ? JSON.parse(process.env.REACT_APP_GHOSTS_ENABLED) : false;

if (isGhostsEnabled) {
  appRoutes = appRoutes.concat([
    {
      exact: true,
      path: '/app/templates/:id/edit/ghosts-mapping',
      component: lazy(() => import('src/views/templates/GhostsMappingView'))
    },
    {
      exact: true,
      path: '/app/shooting-templates',
      component: lazy(() => import('src/views/shootingTemplates/ListView'))
    },
    {
      exact: true,
      path: '/app/shooting-templates/create',
      component: lazy(() => import('src/views/shootingTemplates/CreateView'))
    },
    {
      exact: true,
      path: '/app/shooting-templates/:id/edit',
      component: lazy(() => import('src/views/shootingTemplates/EditView'))
    }
  ]);
}

appRoutes.push({
  component: () => <Redirect to="/404" />
});

const routesConfig = [
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/pages/Error403View'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/oauth/callback',
    component: lazy(() => import('src/components/Oauth/Callback'))
  },
  {
    path: '/app',
    guard: AdminGuard,
    layout: DashboardLayout,
    routes: appRoutes
  },
  {
    path: '*',
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
