import _ from 'lodash';
import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class ShootingTemplateNodesService {
    fetchNodes = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'shooting-templates/nodes', params);
      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    updateNode = (id, params) => new Promise((resolve, reject) => {
      axios.patch(`shooting-templates/nodes/${id}`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    createMedia = (id, params) => new Promise((resolve, reject) => {
      axios.post(`shooting-templates/nodes/${id}/media`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteMedia = (id, mediaId) => new Promise((resolve, reject) => {
      axios.delete(`shooting-templates/nodes/${id}/media/${mediaId}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    getSubTree = (nodes, parentId) => {
      const subTree = _.filter(nodes, (node) => (node.parent_id === parentId));
      return _.sortBy(subTree, ['_left']);
    };
}

const shootingTemplateNodesService = new ShootingTemplateNodesService();

export default shootingTemplateNodesService;
