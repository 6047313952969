import CriterionTypesService from 'src/services/criterionTypesService';

export const SET_CRITERION_TYPE = '@criterion-types/set-criterion-type';
export const SET_CRITERION_TYPES_LIST = '@criterion-types/set-criterion-types-list';
export const SET_CRITERION_TYPES_LIST_PARAMS = '@criterion-types/set-criterion-types-list-params';

export function fetchCriterionType(id) {
  return async (dispatch) => {
    await CriterionTypesService.fetchCriterionType(id).then((response) => {
      dispatch({
        type: SET_CRITERION_TYPE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function fetchCriterionTypes() {
  return async (dispatch, getState) => {
    const { criterionTypes: state } = getState();
    const { listParams: params } = state;

    await CriterionTypesService.fetchCriterionTypes(params).then((response) => {
      const items = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_CRITERION_TYPES_LIST,
        payload: {
          items,
          pagination
        }
      });
    });
  };
}

export function updateListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_CRITERION_TYPES_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}

export function deleteCriterionType(id) {
  // The function is here even if it doesn't impact the
  // redux state because I prefer to keep homogeneity and to be ready fo future changes
  return async () => {
    await CriterionTypesService.deleteCriterionType(id);
  };
}

export function updateCriterionType(id, params) {
  return async (dispatch) => {
    await CriterionTypesService.updateCriterionTemplate(id, params).then((response) => {
      dispatch({
        type: SET_CRITERION_TYPE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function createCriterionType(params) {
  return async (dispatch) => {
    await CriterionTypesService.createCriterionType(params).then((response) => {
      dispatch({
        type: SET_CRITERION_TYPE,
        payload: {
          item: response,
        }
      });
    });
  };
}

export function setCriterionType(item) {
  return (dispatch) => {
    dispatch({
      type: SET_CRITERION_TYPE,
      payload: {
        item,
      }
    });
  };
}
