import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class ModelChangeItemsService {
    fetchModelChangeItems = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'model-change-items', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    createModelChangeItem = (attributes) => new Promise((resolve, reject) => {
      axios.post('model-change-items', attributes).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteModelChangeItem = (id) => new Promise((resolve, reject) => {
      axios.delete(`model-change-items/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const modelChangeItemsService = new ModelChangeItemsService();

export default modelChangeItemsService;
