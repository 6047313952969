/* eslint-disable no-param-reassign */
import produce from 'immer';
import _ from 'lodash';
import { ADD_ENTITIES } from 'src/actions/baseActions';
import { REMOVE_MODEL_CHANGE_ITEM } from 'src/actions/templates/modelChangeItemsActions';

const initialState = {
  entities: {},
};

const modelChangeItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { modelChangeItems } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...modelChangeItems };
      });
    }

    case REMOVE_MODEL_CHANGE_ITEM: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        draft.entities = _.filter(state.entities, (entity) => entity.id !== id);
      });
    }

    default: {
      return state;
    }
  }
};

export default modelChangeItemsReducer;
