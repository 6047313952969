import ScopesService from 'src/services/scopesService';

export const SET_SCOPES_LIST = '@scopes/set-scopes-list';
export const SET_SCOPES_LIST_PARAMS = '@scopes/set-scopes-list-params';

export function fetchScopes() {
  return async (dispatch, getState) => {
    const { scopes: state } = getState();
    const { listParams: params } = state;

    await ScopesService.fetchScopes(params).then((response) => {
      const scopes = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_SCOPES_LIST,
        payload: {
          scopes,
          pagination
        }
      });
    });
  };
}

export function updateListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_SCOPES_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}
