/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_USER,
  SET_USERS_LIST,
  SET_USERS_LIST_PARAMS,
  SET_PRODUCTS_EXCLUDED_LIST,
  SET_PRODUCTS_EXCLUDED_LIST_PARAMS
} from 'src/actions/usersActions';
import { DELETE_MEDIA } from 'src/actions/mediaActions';
import _ from 'lodash';

const initialState = {
  user: null,
  list: [],
  listParams: {
    includes: [],
    sortBy: [],
    limit: 50,
    filters: [],
    page: 1
  },
  pagination: {
    currentPage: 1,
    from: 1,
    to: 50,
    lastPage: 1,
    perPage: 50,
    total: 0,
  },
  productsExcluded: {
    list: [],
    listParams: {
      includes: [],
      sortBy: [],
      limit: 10,
      filters: [],
      page: 1
    },
    pagination: {
      currentPage: 1,
      from: 1,
      to: 10,
      lastPage: 1,
      perPage: 1000,
      total: 0,
    }
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case SET_USERS_LIST: {
      const { users, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.list = users;
        draft.pagination = pagination;
      });
    }

    case SET_USERS_LIST_PARAMS: {
      const { listParams } = action.payload;

      return produce(state, (draft) => {
        draft.listParams = { ...state.listParams, ...listParams };
      });
    }

    case SET_PRODUCTS_EXCLUDED_LIST: {
      const { items, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.productsExcluded.list = items;
        draft.productsExcluded.pagination = pagination;
      });
    }

    case SET_PRODUCTS_EXCLUDED_LIST_PARAMS: {
      const { listParams } = action.payload;

      return produce(state, (draft) => {
        draft.productsExcluded.listParams = {
          ...state.productsExcluded.listParams,
          ...listParams
        };
      });
    }

    case DELETE_MEDIA: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        if (state.user) {
          const media = [...state.user.media];
          _.remove(media, (item) => item.id === id);
          draft.user = { ...state.user };
          draft.user.media = media;
        } else {
          draft.user = null;
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default usersReducer;
