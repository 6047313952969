import axios from 'src/utils/axios';

class LocalesService {
    fetchLocales = () => new Promise((resolve, reject) => {
      axios.get('locales').then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const localesService = new LocalesService();

export default localesService;
