import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class CriterionTypesService {
    fetchCriterionTypes = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'criterion-types', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    fetchCriterionType = (id) => new Promise((resolve, reject) => {
      axios.get(`criterion-types/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    createCriterionType = (params) => new Promise((resolve, reject) => {
      axios.post('criterion-types', params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    updateCriterionType = (id, params) => new Promise((resolve, reject) => {
      axios.patch(`criterion-types/${id}`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteCriterionType = (id) => new Promise((resolve, reject) => {
      axios.delete(`criterion-types/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const criterionTypesService = new CriterionTypesService();

export default criterionTypesService;
