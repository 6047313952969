import ProductsService from 'src/services/productsService';

export const SET_PRODUCT = '@products/set-product';
export const SET_PRODUCTS_LIST = '@products/set-products-list';
export const SET_PRODUCTS_LIST_PARAMS = '@products/set-products-list-params';

export function fetchProducts() {
  return async (dispatch, getState) => {
    const { products: state } = getState();
    const { listParams: params } = state;

    await ProductsService.fetchProducts(params).then((response) => {
      const items = response.data;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };
      dispatch({
        type: SET_PRODUCTS_LIST,
        payload: {
          items,
          pagination
        }
      });
    });
  };
}

export function updateListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTS_LIST_PARAMS,
      payload: {
        listParams: params
      }
    });
  };
}
