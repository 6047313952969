import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class TemplatesService {
    fetchTemplates = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'templates', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    fetchTemplate = (id, includes) => new Promise((resolve, reject) => {
      const params = {
        includes
      };
      const url = queryBuilderService.makeUrl('', `templates/${id}`, params);
      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    deleteTemplate = (id, force = false) => new Promise((resolve, reject) => {
      let url = `templates/${id}`;
      if (force === true) {
        url += '?force=true';
      }

      axios.delete(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    createTemplate = (params) => new Promise((resolve, reject) => {
      axios.post('templates', params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    updateTemplate = (id, params) => new Promise((resolve, reject) => {
      axios.patch(`templates/${id}`, params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    restoreTemplate = (id) => new Promise((resolve, reject) => {
      axios.post(`templates/${id}/restore`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    })

    uploadScoringTreeFile = (id, file) => new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      axios.post(`import/templates/${id}/scoring-tree`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    downloadScoringTreeFile = (id) => new Promise((resolve, reject) => {
      axios.get(`export/templates/${id}/scoring-tree`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });

    deleteMedia = (id, mediaId) => new Promise((resolve, reject) => {
      axios.delete(`templates/${id}/media/${mediaId}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const templatesService = new TemplatesService();

export default templatesService;
