/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR
} from 'src/actions/snackbarActions';

const initialState = {
  list: [],
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR: {
      const { notification } = action.payload;

      return produce(state, (draft) => {
        draft.list = state.list;
        draft.list.push(notification);
      });
    }

    case CLOSE_SNACKBAR: {
      const { key, dismissAll } = action.payload;

      return produce(state, (draft) => {
        draft.list = state.list.map((notification) => (
          (dismissAll || notification.key === key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        ));
      });
    }

    case REMOVE_SNACKBAR: {
      const { key } = action.payload;

      return produce(state, (draft) => {
        draft.list = state.list.filter(
          (notification) => notification.key !== key,
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default snackbarReducer;
