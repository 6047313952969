import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class RolesService {
    fetchRoles = (params) => new Promise((resolve, reject) => {
      const url = queryBuilderService.makeUrl('', 'roles', params);

      axios.get(url).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
}

const rolesService = new RolesService();

export default rolesService;
