import axios from 'src/utils/axios';
import queryBuilderService from './queryBuilderService';

class ShootingTemplatesService {
  fetchShootingTemplates = (params) => new Promise((resolve, reject) => {
    const url = queryBuilderService.makeUrl('', 'shooting-templates', params);

    axios.get(url).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  fetchShootingTemplate = (id, includes) => new Promise((resolve, reject) => {
    const params = {
      includes
    };
    const url = queryBuilderService.makeUrl('', `shooting-templates/${id}`, params);
    axios.get(url).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })

  createShootingTemplate = (params) => new Promise((resolve, reject) => {
    axios.post('shooting-templates', params).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  updateShootingTemplate = (id, params) => new Promise((resolve, reject) => {
    axios.patch(`shooting-templates/${id}`, params).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  deleteShootingTemplate = (id, force = false) => new Promise((resolve, reject) => {
    let url = `shooting-templates/${id}`;
    if (force === true) {
      url += '?force=true';
    }

    axios.delete(url).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })

  restoreShootingTemplate = (id) => new Promise((resolve, reject) => {
    axios.post(`shooting-templates/${id}/restore`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  downloadZipFile = (id) => new Promise((resolve, reject) => {
    axios.get(`export/shooting-templates/${id}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });

  uploadZipFile = (id, file) => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    axios.post(`import/shooting-templates/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
}

const shootingTemplatesService = new ShootingTemplatesService();

export default shootingTemplatesService;
