/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CRITERION_TEMPLATE,
  SET_CRITERION_TEMPLATES_LIST,
  SET_CRITERION_TEMPLATES_LIST_PARAMS
} from 'src/actions/criterionTemplatesActions';

const initialState = {
  criterionTemplate: null,
  list: [],
  listParams: {
    includes: ['type', 'scope'],
    sortBy: [],
    limit: 1000,
    filters: [],
    page: 1
  },
  pagination: {
    currentPage: 1,
    from: 1,
    to: 10,
    lastPage: 1,
    perPage: 10,
    total: 0,
  }
};

const criterionTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CRITERION_TEMPLATE: {
      const { item } = action.payload;

      return produce(state, (draft) => {
        draft.criterionTemplate = item;
      });
    }

    case SET_CRITERION_TEMPLATES_LIST: {
      const { items, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.list = items;
        draft.pagination = pagination;
      });
    }

    case SET_CRITERION_TEMPLATES_LIST_PARAMS: {
      const { listParams } = action.payload;

      return produce(state, (draft) => {
        draft.listParams = { ...state.listParams, ...listParams };
      });
    }

    default: {
      return state;
    }
  }
};

export default criterionTemplatesReducer;
