import LocalesService from 'src/services/localesService';

export const SET_LOCALES_LIST = '@locales/set-locales-list';

export function fetchLocales() {
  return async (dispatch) => {
    await LocalesService.fetchLocales().then((response) => {
      const list = response;
      dispatch({
        type: SET_LOCALES_LIST,
        payload: {
          list
        }
      });
    });
  };
}
