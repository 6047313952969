/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ADD_ENTITIES } from 'src/actions/baseActions';
import { DELETE_MEDIA } from 'src/actions/mediaActions';

const initialState = {
  entities: {}
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { media } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...media };
      });
    }

    case DELETE_MEDIA: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        const { [id]: entity, ...rest } = state.entities;
        draft.entities = rest;
      });
    }

    default: {
      return state;
    }
  }
};

export default mediaReducer;
