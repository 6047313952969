/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_TEMPLATES_UI_LIST,
  SET_TEMPLATES_UI_LIST_PARAMS,
  SET_TEMPLATES_UI_TRASH,
} from 'src/actions/templatesActions';
import {
  UPDATE_TEMPLATES_UI_MANAGE_INSTRUCTIONS
} from 'src/actions/templates/manageInstructionsActions';
import {
  UPDATE_TEMPLATES_UI_GHOSTS_MAPPING,
  UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL
} from 'src/actions/templates/ghostsMappingActions';
import { DELETE_MEDIA } from 'src/actions/mediaActions';
import { ADD_ENTITIES } from 'src/actions/baseActions';
import _ from 'lodash';

const initialState = {
  entities: {},
  ui: {
    list: {
      ids: [],
      params: {
        includes: [],
        sortBy: [],
        limit: 1000,
        filters: [],
        page: 1
      },
      pagination: {
        currentPage: 1,
        from: 1,
        to: 10,
        lastPage: 1,
        perPage: 10,
        total: 0,
      }
    },
    trash: {
      ids: [],
      params: {
        includes: [],
        sortBy: [],
        limit: 1000,
        filters: [
          ['trashed', 'only']
        ],
        page: 1
      },
      pagination: {
        currentPage: 1,
        from: 1,
        to: 10,
        lastPage: 1,
        perPage: 10,
        total: 0,
      }
    },
    manageInstructions: {
      id: null,
      params: {
        expandAction: null,
        lastExpandAction: null
      },
      nodeDefinition: {
        file: null,
        imageIdentifier: null,
        relatedNodeDefinitionIds: []
      }
    },
    ghostsMapping: {
      id: null,
      shootingTemplateId: null,
      params: {
        expandAction: null,
        lastExpandAction: null,
      },
      modal: {
        open: false,
        nodeDefinitionId: null,
        shootingTemplateNodeIds: [],
        selectedShootingTemplateNodeIds: [],
        shootingTemplateNodeIdsTouched: [],
        params: {
          includes: [],
          sortBy: [],
          limit: 12,
          filters: [],
          page: 1
        },
        pagination: {
          currentPage: 1,
          from: 1,
          to: 12,
          lastPage: 1,
          perPage: 12,
          total: 0,
        }
      }
    }
  }
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { templates } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...templates };
      });
    }

    case SET_TEMPLATES_UI_LIST: {
      const { ids, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.ui.list.ids = ids;
        draft.ui.list.pagination = pagination;
      });
    }

    case SET_TEMPLATES_UI_LIST_PARAMS: {
      const { params } = action.payload;

      return produce(state, (draft) => {
        draft.ui.list.params = { ...state.ui.list.params, ...params };
      });
    }

    case SET_TEMPLATES_UI_TRASH: {
      const { ids, pagination, params } = action.payload;

      return produce(state, (draft) => {
        if (ids !== undefined) {
          draft.ui.trash.ids = ids;
        }

        if (pagination !== undefined) {
          draft.ui.trash.pagination = pagination;
        }

        if (params !== undefined) {
          draft.ui.trash.params = { ...state.ui.trash.params, ...params };
          draft.ui.trash.params.filters.push(['trashed', 'only']);
        }
      });
    }

    case UPDATE_TEMPLATES_UI_MANAGE_INSTRUCTIONS: {
      const { id, params, nodeDefinition } = action.payload;

      return produce(state, (draft) => {
        if (id !== undefined) {
          draft.ui.manageInstructions.id = id;
        }
        if (params !== undefined) {
          draft.ui.manageInstructions.params = { ...state.ui.manageInstructions.params, ...params };
        }
        if (nodeDefinition !== undefined) {
          draft.ui.manageInstructions.nodeDefinition = {
            ...state.ui.manageInstructions.nodeDefinition,
            ...nodeDefinition
          };
        }
      });
    }

    case UPDATE_TEMPLATES_UI_GHOSTS_MAPPING: {
      const { id, shootingTemplateId, params } = action.payload;

      return produce(state, (draft) => {
        if (id !== undefined) {
          draft.ui.ghostsMapping.id = id;
        }
        if (shootingTemplateId !== undefined) {
          draft.ui.ghostsMapping.shootingTemplateId = shootingTemplateId;
        }
        if (params !== undefined) {
          draft.ui.ghostsMapping.params = { ...state.ui.ghostsMapping.params, ...params };
        }
      });
    }

    case UPDATE_TEMPLATES_UI_GHOSTS_MAPPING_MODAL: {
      const {
        open,
        nodeDefinitionId,
        shootingTemplateNodeIds,
        selectedShootingTemplateNodeIds,
        shootingTemplateNodeIdsTouched,
        params,
        pagination
      } = action.payload;

      return produce(state, (draft) => {
        if (open !== undefined) {
          draft.ui.ghostsMapping.modal.open = open;
        }
        if (nodeDefinitionId !== undefined) {
          draft.ui.ghostsMapping.modal.nodeDefinitionId = nodeDefinitionId;
        }
        if (shootingTemplateNodeIds !== undefined) {
          draft.ui.ghostsMapping.modal.shootingTemplateNodeIds = shootingTemplateNodeIds;
        }
        if (selectedShootingTemplateNodeIds !== undefined) {
          draft.ui.ghostsMapping.modal.selectedShootingTemplateNodeIds = selectedShootingTemplateNodeIds;
        }
        if (shootingTemplateNodeIdsTouched !== undefined) {
          draft.ui.ghostsMapping.modal.shootingTemplateNodeIdsTouched = shootingTemplateNodeIdsTouched;
        }
        if (params !== undefined) {
          draft.ui.ghostsMapping.modal.params = {
            ...state.ui.ghostsMapping.modal.params,
            ...params
          };
        }
        if (pagination !== undefined) {
          draft.ui.ghostsMapping.modal.pagination = pagination;
        }
      });
    }

    case DELETE_MEDIA: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities };
        const filtered = _.filter(
          draft.entities,
          (entity) => entity.media && entity.media.includes(id)
        );
        _.forEach(filtered, (entity) => {
          const clone = { ...entity };
          const media = _.without(entity.media, id);
          clone.media = media;
          draft.entities[clone.id] = clone;
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default templatesReducer;
