import ShootingTemplatesService from 'src/services/shootingTemplatesService';
import { addEntities } from 'src/actions/baseActions';
import { normalize } from 'normalizr';
import { shootingTemplate as shootingTemplateSchema } from 'src/schema';

export const SET_SHOOTING_TEMPLATES_UI_LIST = '@shooting-templates/set-ui-list';
export const SET_SHOOTING_TEMPLATES_UI_LIST_PARAMS = '@shooting-templates/set-ui-list-params';
export const SET_SHOOTING_TEMPLATES_UI_TRASH = '@shooting-templates/set-ui-trash';
export const SET_SHOOTING_TEMPLATES_UI_EDIT = '@shooting-templates/set-ui-edit';

export function fetchShootingTemplate(id, includes = []) {
  return async (dispatch) => {
    await ShootingTemplatesService.fetchShootingTemplate(id, includes).then((response) => {
      const normalized = normalize(response, shootingTemplateSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function fetchShootingTemplates() {
  return async (dispatch, getState) => {
    const { shootingTemplates: state } = getState();
    const { ui } = state;

    await ShootingTemplatesService.fetchShootingTemplates(ui.list.params).then((response) => {
      const normalized = normalize(response.data, [shootingTemplateSchema]);

      dispatch(addEntities(normalized.entities));

      const ids = normalized.result;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };

      dispatch({
        type: SET_SHOOTING_TEMPLATES_UI_LIST,
        payload: {
          ids,
          pagination
        }
      });
    });
  };
}

export function fetchShootingTemplatesDeleted() {
  return async (dispatch, getState) => {
    const { shootingTemplates: state } = getState();
    const { ui } = state;

    await ShootingTemplatesService.fetchShootingTemplates(ui.trash.params).then((response) => {
      const normalized = normalize(response.data, [shootingTemplateSchema]);
      dispatch(addEntities(normalized.entities));

      const ids = normalized.result;
      const pagination = {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        to: response.meta.to,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      };

      dispatch({
        type: SET_SHOOTING_TEMPLATES_UI_TRASH,
        payload: {
          ids,
          pagination
        }
      });
    });
  };
}

export function updateUiTrashParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOOTING_TEMPLATES_UI_TRASH,
      payload: {
        params
      }
    });
  };
}

export function updateUiListParams(params) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOOTING_TEMPLATES_UI_LIST_PARAMS,
      payload: {
        params
      }
    });
  };
}

export function updateUiEdit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOOTING_TEMPLATES_UI_EDIT,
      payload: { ...data }
    });
  };
}

export function updateUiTrash(data) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOOTING_TEMPLATES_UI_TRASH,
      payload: { ...data }
    });
  };
}

export function updateShootingTemplate(id, params) {
  return async (dispatch) => {
    await ShootingTemplatesService.updateShootingTemplate(id, params).then((response) => {
      const normalized = normalize(response, shootingTemplateSchema);
      dispatch(addEntities(normalized.entities));
    });
  };
}

export function createShootingTemplate(params) {
  return async (dispatch) => {
    const response = await ShootingTemplatesService.createShootingTemplate(params);
    const normalized = normalize(response, shootingTemplateSchema);
    dispatch(addEntities(normalized.entities));
    return response.id;
  };
}

export function deleteShootingTemplate(id, force = false) {
  return async () => {
    await ShootingTemplatesService.deleteShootingTemplate(id, force);
  };
}

export function restoreShootingTemplate(id) {
  return async () => {
    await ShootingTemplatesService.restoreShootingTemplate(id);
  };
}
