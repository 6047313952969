/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_ENTITIES
} from 'src/actions/baseActions';
import { DELETE_MEDIA } from 'src/actions/mediaActions';
import _ from 'lodash';

const initialState = {
  entities: {}
};

const shootingTemplateNodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTITIES: {
      const { shootingTemplateNodes } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities, ...shootingTemplateNodes };
      });
    }

    case DELETE_MEDIA: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        draft.entities = { ...state.entities };
        const filtered = _.filter(
          draft.entities,
          (entity) => entity.media && entity.media.includes(id)
        );
        _.forEach(filtered, (entity) => {
          const clone = { ...entity };
          const media = _.without(entity.media, id);
          clone.media = media;
          draft.entities[clone.id] = clone;
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default shootingTemplateNodesReducer;
