/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_ROLES_LIST
} from 'src/actions/rolesActions';

const initialState = {
  list: [],
  listParams: {
    includes: [],
    sortBy: [],
    limit: 1000,
    filters: [],
    page: 1
  },
  pagination: {
    currentPage: 1,
    from: 1,
    to: 10,
    lastPage: 1,
    perPage: 10,
    total: 0,
  }
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES_LIST: {
      const { list, pagination } = action.payload;

      return produce(state, (draft) => {
        draft.list = list;
        draft.pagination = pagination;
      });
    }

    default: {
      return state;
    }
  }
};

export default rolesReducer;
