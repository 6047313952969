import _ from 'lodash';
import TemplatesService from 'src/services/templatesService';
import NodeDefinitionService from 'src/services/nodeDefinitionsService';
import { addEntities } from 'src/actions/baseActions';
import { normalize } from 'normalizr';
import { template as templateSchema, nodeDefinition as nodeDefinitionSchema } from 'src/schema';

export const UPDATE_TEMPLATES_UI_MANAGE_INSTRUCTIONS = '@templates/update-ui-manage-instructions';

export function updateUiManageInstructions(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATES_UI_MANAGE_INSTRUCTIONS,
      payload: { ...data }
    });
  };
}

export function fetchRelatedTemplates(nodeDefinition) {
  return async (dispatch) => {
    // Get related node definitions
    const filters = [];
    filters.push(['element_id', nodeDefinition.element_id]);
    filters.push(['neq:id', nodeDefinition.id]); // neq = not equal
    const NodeDefinitionsResponse = await NodeDefinitionService.fetchNodeDefinitions({
      limit: 1000,
      filters,
      includes: ['media']
    });
    const normalizedNodeDefinitions = normalize(
      NodeDefinitionsResponse.data,
      [nodeDefinitionSchema]
    );
    dispatch(addEntities(normalizedNodeDefinitions.entities));

    // Get related templates
    const templateIds = _.reduce(NodeDefinitionsResponse.data, (result, entity) => {
      if (entity.template_id !== nodeDefinition.template_id) {
        result.push(entity.template_id);
      }

      return result;
    }, []);

    if (templateIds.length > 0) {
      // Fetch related templates only if at least one exists
      const templatesResponse = await TemplatesService.fetchTemplates({
        limit: 1000,
        filters: [
          ['_id', templateIds]
        ],
        includes: [
          'media'
        ]
      });
      const normalizedTemplates = normalize(templatesResponse.data, [templateSchema]);
      dispatch(addEntities(normalizedTemplates.entities));
    }

    // Spread informations about ids
    dispatch(updateUiManageInstructions({
      nodeDefinition: {
        relatedNodeDefinitionIds: normalizedNodeDefinitions.result
      }
    }));
  };
}
